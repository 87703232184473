%bannerSize--thin {
  height: 240px;

  @include media-breakpoint-up(sm) { // 576px and up
    height: 250px;
  }
  @include media-breakpoint-up(md) { // 768px and
    height: 260px;
  }
  @include media-breakpoint-up(lg) { // 992px and up
    height: 280px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    height: 310px;
  }
  @media (min-width: 1601px) {
    height: 330px;
  }
  @media (min-width: 1901px) {
    height: 350px;
  }
  @media (min-width: 2201px) {
    height: 360px;
  }
}
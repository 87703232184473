.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  //z-index: 3;

  &.bannerHasTitlePanel::before {
    content: "";
    background: rgba(0, 0, 0, 0.3333);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
}

.banner_item__text {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-base;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    font-size: 2.3rem;
    margin-bottom: -0.3rem;
  }
}

.banner-item__link {
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  padding: 0.4rem 1rem;
  background-color: $primary-color;
  color: #fff;
  margin-top: 1rem;

  @include media-breakpoint-up(lg) {
  }

  &:hover,
  &:focus {
    color: #fff;
    background-color: $link-hover-color;
    text-decoration: none;
  }
}

.bannerItem__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

div.banner-item-container {
  position: relative;
  background-color: white;

  .text-layer {
    position: absolute;
    transform: translateY(-50%);
    z-index: 4;
    //max-width: 460px;
    max-width: 192px;
    top: 50%;
    //left: 70%;
    left: 53%;
    //border: 1px solid red;
    @include media-breakpoint-up(sm) {
      top: 50%;
      left: 57%;
      max-width: 460px;
    }
    .banner-item__title {
      //font-size: 1.2rem;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 0.9;
      color: white;
      @include media-breakpoint-up(sm) {
        font-size: 2.9rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 2.1rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2.9rem;
      }
    }
    .banner-item__extra__caption {
      font-size: 1rem;
      color: white;
      @include media-breakpoint-up(sm) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.8rem;
      }
    }
    .banner-item__button {
      display: inline-block;
      background-color: black;
      padding: 0.1rem 0.7rem;
      color: white;
      border-radius: 10px;
      font-weight: 600;
      margin-top: 0.5rem;
      font-size: 0.8rem;
      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
    }
  }

  .banner-layer {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    left: 15%;
    &.no__title {
      left: 48%;
    }
    @include media-breakpoint-up(sm) {
      left: 38%;
      &.no__title {
        left: 48%;
      }
    }
  }
  .banner-item-dot {
    animation: bixAxis 5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
  }

  .banner-item-dot::after {
    content: "";
    display: block;
    animation: biyAxis 5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 300px;
    height: 300px;
    @include media-breakpoint-up(sm) {
      // 576px and up
      width: calc((900 / 40) * var(--useLayerOption));
      height: calc((900 / 40) * var(--useLayerOption));
    }
    @media (min-width: 2450px) {
      width: 900px;
      height: 900px;
    }
  }

  .big-layer {
    position: absolute;
    top: 50%;
    left: 42%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @include media-breakpoint-up(sm) {
      left: 50%;
    }
  }

  .big-dot {
    animation: bxAxis 7s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    @include media-breakpoint-up(sm) {
      animation: bxAxisBigSize 7s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    }
  }

  .big-dot::after {
    content: "";
    display: block;
    border-radius: 50%;
    //background-color: black;
    animation: byAxis 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    width: 450px;
    height: 450px;
    @include media-breakpoint-up(sm) {
      // 576px and up
      width: calc((1500 / 40) * var(--useLayerOption));
      height: calc((1500 / 40) * var(--useLayerOption));
      animation: byAxisBigSize 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }
    @include media-breakpoint-up(md) {
      // 576px and up
      width: calc(48 * var(--useLayerOption));
      height: calc(48 * var(--useLayerOption));
      animation: byAxisBigSize 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }
    @include media-breakpoint-up(xxl) {
      // 1600px and up
      width: calc((1500 / 40) * var(--useLayerOption));
      height: calc((1500 / 40) * var(--useLayerOption));
      animation: byAxisBigSize 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }

    @media (min-width: 2450px) {
      width: 1500px;
      height: 1500px;
    }
  }

  .small-layer {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 4;
    top: 88%;
    left: 15%;
    @include media-breakpoint-up(sm) {
      top: 52%;
      left: 18%;
    }
  }

  .small-dot {
    animation: sxAxisM 12s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    @include media-breakpoint-up(sm) {
      animation: sxAxis 7s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    }
  }

  .small-dot::after {
    content: "";
    display: block;
    border-radius: 50%;
    //background-color: black;
    animation: syAxis 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    width: 100px;
    height: 100px;
    @include media-breakpoint-up(sm) {
      width: calc((300 / 40) * var(--useLayerOption));
      height: calc((300 / 40) * var(--useLayerOption));
    }
    @media (min-width: 2450px) {
      width: 350px;
      height: 350px;
    }
  }

  .layer-item.magenta {
    &::after {
      background-color: $color-magenta;
    }
  }

  .layer-item.green {
    &::after {
      background-color: $color-green;
    }
  }

  .layer-item.purple {
    &::after {
      background-color: $color-purple;
    }
  }

  .layer-item.red {
    &::after {
      background-color: $color-red;
    }
  }

  .layer-item.yellow {
    &::after {
      background-color: $color-yellow;
    }
  }

  .layer-item.blue {
    &::after {
      background-color: $color-blue;
    }
  }

  .layer-item.pink {
    &::after {
      background-color: $color-pink;
    }
  }
}

:root {
  --useLayerOption: 16px;
  @media screen and (min-width: 300px) {
    --useLayerOption: calc(16px + (20 - 16) * ((100vw - 320px) / (768 - 320)));
  }
  @media screen and (min-width: 600px) {
    --useLayerOption: calc(20px + (40 - 20) * ((100vw - 768px) / (1920 - 768)));
  }
  @media screen and (min-width: 1920px) {
    //--brilliance2Base: 40px;
    --useLayerOption: calc(20px + (40 - 20) * ((100vw - 768px) / (1920 - 768)));
  }
  @media screen and (min-width: 2450px) {
    --useLayerOption: 40px;
  }
}

@keyframes biyAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: translateY(-2%) scale(1.05);
  }
}

@keyframes bixAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(10%);
  }
}

@keyframes byAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: translateY(-5%);
  }
}

@keyframes byAxisBigSize {
  50% {
    //animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: translateY(-1%);
  }
}

@keyframes bxAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    //transform: translateX(5%);
    transform: translateX(9%);
  }
}

@keyframes bxAxisBigSize {
  50% {
    //animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(-10%);
  }
}

@keyframes syAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: translateY(-40%);
  }
}

@keyframes sxAxis {
  50% {
    //animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(15%);
  }
}

@keyframes sxAxisM {
  50% {
    //animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(80%);
  }
}

.htmlPanel {
  max-width: 960px;
  //max-width: 1400px;
  margin: auto;
}

div.imagePanel{
  position: relative;
}

div.imagePanel .htmlPanel{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img{
    max-width: 32%; 
    @media (min-width: 456px) { 
      max-width: 26%;
    }
    @include media-breakpoint-up(sm) { // 576px and up
      max-width: 18%;
    }
    @include media-breakpoint-up(md) { // 768px and
      max-width: 17%;
    }
    @include media-breakpoint-up(lg) { // 992px and up
      max-width: 20%;
    }
    // @include media-breakpoint-up(xl) { // 1200px and up
    //   max-width: 20%;
    // }
    @media (min-width: 1601px) {
      max-width: 100%;
    }
  }
}
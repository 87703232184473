@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}
body:not(.cmsedit) {
  div.cms_item.ig-feed-desktop {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.ig-feed-mobile {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

div.search-result.productList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
}

div.social__media {
  max-width: 960px;
  margin: auto;
}

.htmlPanel {
  .embedCodes--pinterest,
  .embedCodes--instagram,
  .embedCodes--instagramlink {
    display: flex;
    justify-content: center;
  }
}

div.hide-blog-title-true {
  .blogView__title {
    display: none !important;
  }
}